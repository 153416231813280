<template>
  <div :style="'background:' + current_background">
    <span class="font-scale font-medium">#{{ task.id }}</span>, {{ task.created_date }}
    <br/>
    от
    <span class="font-medium" v-if="task.creator">
      {{ task.creator.name }}
    </span>
    <span class="font-medium" v-if="!task.creator && task.client">
      {{ task.client.name }}
    </span>

    <span v-if="task.creator || task.client" class="label font-small-xxs"
          :class="task.creator ? 'label-primary' : ((!task.creator && task.client) ? 'label-success' : 'label-default')">
      {{ task.creator ? 'Сотрудник' : ((!task.creator && task.client) ? 'Клиент' : '') }}
    </span>

    <hr class="mt-1 mb-1"/>
  </div>
</template>

<script>
export default {
  name: 'task-item-main-block',

  props: {
    task: {
      type: Object,
    },
    current_background: {
      type: [String, null],
      default: null,
    }
  },
}
</script>

<style scoped>

</style>
